import { BadRequest } from '@tsed/exceptions';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { commonConfig, gameResultConfig, homeConfig } from '@/lib/i18n/configs';
import Login from '@/pages/login';

type Props = {
  /** Add Route props here */
};

export default function HomeIndex(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return <Login />;
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const i18nNamespaces = [
    ...homeConfig.i18nNamespaces,
    ...commonConfig.i18nNamespaces,
    ...gameResultConfig.i18nNamespaces,
  ];

  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
