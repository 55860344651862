import styled from '@emotion/styled';
import { BadRequest } from '@tsed/exceptions';
import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import LoginForm from '@/components/profile/LoginForm';
import Logo from '../../public/images/logo/caesar.png';
import { homeConfig, commonConfig } from '../lib/i18n/configs';

type Props = {
  /** Add Route props here */
};

const CenterIconDiv = styled.div({
  marginTop: '20px',
  textAlign: 'center',
});

export default function Login(
  _props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return (
    <>
      <CenterIconDiv>
        <Image src={Logo} width={170} height={170} alt="logo" />
      </CenterIconDiv>
      <LoginForm name="loginFormAieia" />
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new BadRequest('locale is missing');
  }
  const i18nNamespaces = [
    ...homeConfig.i18nNamespaces,
    ...commonConfig.i18nNamespaces,
  ];

  return {
    props: {
      ...(await serverSideTranslations(locale, i18nNamespaces.slice())),
    },
  };
};
